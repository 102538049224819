import * as React from "react";
import "./titleDescription.scss";

const TitleDescription = ({ title, description }) => (
  <div className="titleDescription">
    <div className="titleDescription__header">
      <h2>{title}</h2>
    </div>
    <div className="titleDescription__description">
      {description}
    </div>
  </div>
)

export default TitleDescription;
