import React from "react";
import Layout from "../components/layout";
import "../Assets/styles/about.scss";
import ServiceFooter from "../components/ServiceFooter";

//images
import backgroundCard from "./../Assets/images/About/rectangle-background.png";
import rightDotTestomials from "./../Assets/images/Home/testimonials/right-dot.png";
import TitleDescription from "../components/TitleDescription/titleDescription";
import { graphql } from "gatsby";
import { Fade } from "react-reveal";
import Scrolltop from "../components/Scrolltotop/scrolltop";
import Footer from "../components/Footer/Footer";

const ContactPage = () => {
  // const { allAboutusHeaders, allOurCultures, allOurValues, allOurValuesCultures, allWhyWorkWithUs,
  //   allJoinOurTeams } = data

  const data = {
    allOurValues: {
      nodes: [
        {
          data: [
            {
              attributes: {
                title: "Persuasive Leadership",
                description: "Our leaders are skilled to strongly guide and assist businesses on the path towards growth and profits."
              },
            },
            {
              attributes: {
                title: "Teamwork",
                description: "We promote teamwork and collaboration within teams to bring out the best for the business."
              },
            },
            {
              attributes: {
                title: "Innovation",
                description: "Translating clients creative and unique ideas using our expertise to create innovative technology innovation."
              },
            },
            {
              attributes: {
                title: "Communication",
                description: "Our standardized approach creates a working environment of transparency and stronger communication."
              },
            },
            {
              attributes: {
                title: "Excellence",
                description: "We promise to provide new-edge technology solutions with our experience in delivering technology solutions to amplify business success."
              },
            }
          ],
        },
      ],
    },
    allOurCultures: {
      nodes: [
        {
          data: [
            {
              attributes: {
                title: "Passion",
                description: "Our software developers are extremely tech-savvy and deeply inclined to develop high-performing and robust applications."
              },
            },
            {
              attributes: {
                title: "Quality Driven",
                description: "Maintaining quality in all our deliverables makes the process better and enhanced."
              },
            },
            {
              attributes: {
                title: "Curiosity",
                description: "Our team of developers delves deep into the technology cores to offer market-driven and optimally performing applications."
              },
            },
            {
              attributes: {
                title: "Transparency & Accountability",
                description: "We apply constructive self-criticism and follow a transparent approach by providing real-time updates to our clients."
              },
            },
            {
              attributes: {
                title: "Team Ideology",
                description: "Our team is pillared on transparent communication, interactive working and a problem-solving attitude."
              },
            }
          ]
        }
      ]
    }
  }

  return (
    <Layout>
      <div className="about-us">
        <div className="header-title">
          <div className="we-help-client">
            {/* {allAboutusHeaders.nodes[0].data.attributes.title} */}
            We help our clients
          </div>
          <Fade top>
          <div className="to-glow">
            {/* {allAboutusHeaders.nodes[0].data.attributes.content1} */}
            to grow
          </div>
          </Fade>
          <div className="their-business">
            {/* {allAboutusHeaders.nodes[0].data.attributes.content2} */}
            their business
          </div>
        </div>

        <div className="business-sucess">
          {/* {allAboutusHeaders.nodes[0].data.attributes.content3} */}
          Let's elevate your business success
        </div>
        <div className="why-work">
          <TitleDescription
            title="Why Work With Us ?"
            description="Our focused business strategy is designed to enhance client's existing development methodologies and improve their conventional way of business operations. We go the extra mile to meet the quality benchmarks of clients expectations and develop apps that boost their business sales and profits."
          />

          <div className="why-work__cards">
            <img src={backgroundCard} className="why-work__card-background" alt="green-background" />
            <img src={rightDotTestomials} className="why-work__card-background-dot" alt="dot" />
            <div className="why-work__cards-section">
              <Fade right>
              <div className="why-work__card-1">
                {/* <h1>{allOurValuesCultures.nodes[0].data.attributes.valueTitle}</h1> */}
                <h1>Our Values</h1>
                {/* <p>{allOurValuesCultures.nodes[0].data.attributes.valueDesc}</p> */}
                <p>Our core values and demonstrated business experience transform client’s unique business ideas into high-end applications. At Madhav technologies, we imbibe various contributing factors within the solution for businesses to embark on new business journeys and accelerate success with cutting-edge applications.</p>
                {data.allOurCultures.nodes[0].data.map((card, index) => {
                  return (
                    <div key={index}>
                      <h5>{card.attributes.title}</h5>
                      <div className="why-work__card-description">
                        {card.attributes.description}
                      </div>
                    </div>
                  )
                })}
              </div>
              </Fade>
              <Fade left>
              <div className="why-work__card-2">
                {/* <h1>{allOurValuesCultures.nodes[0].data.attributes.cultureTitle}</h1> */}
                <h1>Our Culture</h1>
                {/* <p>{allOurValuesCultures.nodes[0].data.attributes.cultureDesc}</p> */}
                <p>Driven by authenticity, passion and dedication, Madhav technologies offers businesses all the opportunities to thrive and grow with our cutting-edge software development services. We have a smart team of indigenous software developers who are consistent and determined to pave your path to success.</p>
                {data.allOurValues.nodes[0].data.map((card, index) => {
                  return (
                    <div key={index}>
                      <h5>{card.attributes.title}</h5>
                      <div className="why-work__card-description">
                        {card.attributes.description}
                      </div>
                    </div>
                  )
                })}
              </div>
              </Fade>
            </div>
          </div>

          <TitleDescription
            // title={allJoinOurTeams.nodes[0].data.attributes.title}
            title="Join our team"
            // description={allJoinOurTeams.nodes[0].data.attributes.description}
            description="Madhav technologies is equipped with a variety of energetic young people who want to hit a lot of goals while maintaining a high level of quality. We foster a work climate at eTatvaSoft that allows workers to contribute their skills and create creative IT solutions for our clients. As a result, we are able to provide a win-win situation for everyone involved with us, whether it be our clients or our employees."
          />
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
      </div>
      {/* <ContactFooter /> */}
      <Scrolltop />
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allAboutusHeaders {
//       nodes {
//         data {
//           attributes {
//             title
//             content1
//             content2
//             content3
//           }
//         }
//       }
//     }

//     allOurCultures {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//           }
//         }
//       }
//     }

//     allOurValues {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//           }
//         }
//       }
//     }

//     allOurValuesCultures {
//       nodes {
//         data {
//           attributes {
//             valueTitle
//             valueDesc
//             cultureTitle
//             cultureDesc
//           }
//         }
//       }
//     }

//     allWhyWorkWithUs {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//           }
//         }
//       }
//     }

//     allJoinOurTeams {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `;

export default ContactPage;
